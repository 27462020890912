<script setup lang="ts">
import {ref, watch} from "vue";
import {debounce} from 'vue-debounce'

import {getTranslation, setShop} from "@/ts/utilities.ts";

import Modal from "@/components/helpers/Modal.vue";
import Spinner from "@/components/ui/decoration/Spinner.vue";
import RenderAction from "@/components/helpers/RenderAction.vue";
import {MarkerData} from "@/interfaces/MapsViewInterface.ts";

interface Props {
	show: boolean,
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

let shops = ref([])
let shopQuery = ref('')
let isLoading = ref(false)
let filteredShops = ref([])
let selectedShop = ref('')
let changingShop = ref(false)
let changingShopRedirectLink = ref('')
let changingShopRedirectText = ref('')

function close() {
	emit('close')
}

function updateShop() {
	changeShop(selectedShop.value)
}

const loadShopSelector = async () => {
	isLoading.value = true
	await fetch('mobler-api/v2/shops/getshops')
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			shops.value = data.shops
			filteredShops.value = data.shops
			isLoading.value = false
		})
		.catch(error => {
			console.error('There has been a problem with your fetch operation:', error)
		})
}

const changeShop = async (dealer: MarkerData) => {
	try {
		const data = await setShop(dealer)
		if (data.success) {
			if (!data.beforeRedirectConfirmation) {
				window.location.reload()
			} else {
				changingShop.value = true
				changingShopRedirectLink.value = data.redirectLink
				changingShopRedirectText.value = data.beforeRedirectConfirmationText
			}
		}
	} catch (error) {
		console.error('Error changing shop', error)
	}
}

const redirectShop = (redirectLink: string) => {
	location.href = redirectLink
}

watch(shopQuery, debounce((value: string) => {
	if (value.length > 2) {
		filteredShops.value = shops.value.filter((filteredShop) => {
			return filteredShop.SearchKeyWords?.toLowerCase().includes(value.toLowerCase())
		})
	} else {
		filteredShops.value = shops.value
	}
}, 225))

watch(() => props.show, (value) => {
	if (value && shops.value.length === 0) {
		loadShopSelector()
	}
})
</script>

<template>
	<Modal
		:show="props.show"
		@close="close()"
		theme="shops">
		<template v-slot:modal-title>
			{{ getTranslation('HeaderFindShop') }}
		</template>
		<template v-slot:content>
			<Spinner v-if="isLoading"
					 class="w-full">
				{{ getTranslation('ShopSelector.Loading') }}
			</Spinner>
			<template v-else-if="changingShop">
				<div class="p-8 w-full flex flex-col justify-center items-center gap-8 min-h-[50vh]">
					<div class="text-center">
						{{ changingShopRedirectText }}
					</div>
					<div class="flex items-center gap-2">
						<RenderAction
							action="button"
							theme="link"
							@click="changingShop = false"
						>
							{{ getTranslation('ShopRedirect.Cancel') }}
						</RenderAction>
						<RenderAction
							action="button"
							theme="primary"
							@click="redirectShop(changingShopRedirectLink)"
						>
							{{ getTranslation('ShopRedirect.Select') }}
						</RenderAction>
					</div>
				</div>
			</template>
			<div v-else class="min-h-[50vh] max-h-[50vh] w-full">
				<div class="p-8 flex flex-col gap-8 min-h-[50vh]">
					<label>
						<span class="text-sm">{{ getTranslation('ShopSelector.Search.Label') }}</span>
						<input class="form-input rounded-sm border border-beige mt-1 w-full" :placeholder="getTranslation('ShopSelector.Search.Placeholder')" type="text" name="shopQuery" id="shopQuery" v-model="shopQuery" />
					</label>
					<fieldset v-if="filteredShops.length > 0">
						<div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
							<div v-for="(shop, index) in filteredShops" :key="index">
								<label class="flex items-center gap-x-3 rounded border border-beige bg-white p-4 hover:bg-white-hover hover:cursor-pointer transition has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary">
									<input type="radio" name="SelectedShop" :value="shop.MenuText" v-model="selectedShop" required />
									<span>
										<strong>{{ shop.ShopName }}</strong><br>
										{{ shop.Address }}<br>
										{{ shop.PostalAndCity }}
									</span>
								</label>
							</div>
						</div>
					</fieldset>
					<div v-else class="text-center">
						{{ getTranslation('ShopSelector.NoShops') }}
					</div>
				</div>
				<div class="sticky bottom-0 bg-beige-light">
					<div class="w-full">
						<div class="flex justify-between items-center gap-2 p-4 lg:px-8">
							<RenderAction
								action="button"
								theme="link"
								@click="emit('close')"
							>
								{{ getTranslation('ShopSelector.Cancel') }}
							</RenderAction>
							<RenderAction
								action="button"
								theme="primary"
								@click="updateShop"
							>
								{{ getTranslation('ShopSelector.Select') }}
							</RenderAction>
						</div>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>
