<script setup lang="ts">
import ShowModal from '@/components/ShowModal.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps({
	title: String,
	data: {
		type: Array,
		default: () => []
	},
	type: {
		type: String,
		default: 'list',
	}
})
</script>

<template>
	<div class="border border-grey rounded"
		:class="{
			'px-6 py-2': props.type === 'trust',
			'p-6': props.type === 'list'
		}">
		<!-- TODO: Add Translation, FAQ.Title -->
		<h6 v-if="props.title" class="mb-4">{{ props.title }}</h6>
		<ul class="list-none flex flex-col gap-y-2 *:flex *:items-center *:gap-x-2 *:text-sm"
			:class="{
				'divide-y divide-grey': props.type === 'trust'
			}">
			<li
				v-for="(faq, index) in data"
				:key="index"
				:class="{
					'py-2 *:w-full ': props.type === 'trust'
				}"
			>
				<ShowModal
					:modal-title="faq.header"
					:modal-content="faq.contentText"
					:button-options="
						props.type === 'trust'
							? { action: 'button', theme: 'none', size: 'none' }
							: { action: 'button', theme: 'link', size: 'none' }"
				>
					<font-awesome-icon
						v-if="props.type === 'trust' && faq.icon"
						class="text-base"
						:icon="[faq.iconType ? faq.iconType : 'fal', faq.icon]" />
					<span
						:class="props.type === 'trust' ? 'text-sm font-bold' : ''">
						{{ faq.header }}
					</span>

					<font-awesome-icon
						v-if="props.type === 'trust'"
						class="ml-auto"
						:icon="['fal', 'chevron-right']" />
				</ShowModal>
			</li>
		</ul>
	</div>
</template>
