<script setup lang="ts">
import { watch, onMounted, onBeforeUnmount } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { getTranslation } from "@/ts/utilities.ts";

export interface Props {
	show: boolean;
	theme?: string;
	type?: string;
	exit?: string;
	inline?: boolean;
}

const props = withDefaults(defineProps<Props>(),{
	show: false,
	theme: 'normal',
	type: 'modal',
	exit: 'Luk'
})

const emit = defineEmits(['close'])

function keydownHandler(event) {
	if(!props.show) return

	if (event.key === 'Escape') {
		close(event)
	}
}

function close(event) {
	if(props.inline){
		event.stopPropagation()
	}
	if (document.documentElement.classList.contains('overflow-hidden') && document.documentElement.classList.contains('modal-open')) {
		document.documentElement.classList.remove('overflow-hidden', 'modal-open')
	}
	emit('close')
}

// Prevent body scroll, when modal is open
watch(() => props.show, (show) => {
	if (show) {
		document.documentElement.classList.add('overflow-hidden', 'modal-open')
	} else {
		document.documentElement.classList.remove('overflow-hidden', 'modal-open')
	}
},{
	immediate:true
})

onMounted(() => {
	// Listen for keydown events
	window.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
	// Remove keydown event listener
	window.removeEventListener('keydown', keydownHandler)
})
</script>

<template>
	<div
		v-show="show"
		ref="modal"
		class="items-center overflow-hidden z-40 fixed inset-0"
		:class="{ 'flex': show, hidden: !show }"
		role="dialog"
		:aria-hidden="show ? 'false' : 'true'"
		aria-labelledby="popup-headline"
		aria-describedby="popup-description"
	>
		<div
			class="absolute inset-0 bg-black opacity-50"
			aria-hidden="true"
			@click="close"
		></div>
		<div
			class="m-auto container px-0 bg-white relative overflow-y-auto"
			:class="props.type === 'full' ? 'min-h-full max-h-full flex flex-col' : 'min-h-[20rem] max-h-[90vh] rounded-sm'">
			<div class="grid items-center gap-4 py-4 px-4 md:px-8 bg-beige-light"
				 :class="$slots['modal-secondary-title'] ? 'grid-cols-3' : 'grid-cols-2'">
				<div class="text-base font-bold">
					<slot name="modal-title">
						{{ getTranslation('Modal.Placeholder.Title') }}
					</slot>
				</div>

				<div v-if="$slots['modal-secondary-title']" class="justify-self-center">
					<div class="text-center text-pretty text-base font-bold">
						<slot name="modal-secondary-title">
							{{ getTranslation('Modal.Placeholder.Secondary.Title') }}
						</slot>
					</div>
				</div>

				<button
					type="button"
					class="justify-self-end px-3 text-base hover:underline"
					@click="close"
					>
					<span class="mr-2">{{ exit }}</span>
					<font-awesome-icon :icon="['fal', 'close']" />
				</button>
			</div>

			<div class="h-full flex flex-grow overflow-y-auto"
				 :class="{
					'px-4 md:px-8 py-6': props.theme === 'normal'
				}">
				<slot name="content">
					{{ getTranslation('Modal.Placeholder.Content') }}
				</slot>
			</div>

			<div v-if="$slots.footer" class="grid grid-cols-3 gap-4 py-4 px-8 sticky bg-beige-light"
				:class="props.type === 'full' ? 'bottom-0' : 'top-[100vh]'">
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>
