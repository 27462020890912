
import "@fontsource/montserrat/latin-400.css";
import "@fontsource/montserrat/latin-600.css";
import "@fontsource/montserrat/latin-700.css";
import "@fontsource/montserrat/latin-800.css";

// scss modules
import './main.scss';

// web/vue components
import { createApp } from "vue";
import ClickOutside from './directives/ClickOutsideDirective';

// Automatically grab and register components
const modules = import.meta.glob([
	'./components/**/*.vue',
	'./views/**/*.vue',
	], { eager: true })

// settings should be globally available
let settings = {};

async function getSettings() {
	const settingsEndPoint = '/mobler-api/v2/ecom/settings/website'
	const response = await fetch(settingsEndPoint);
	let data;
	try {
		data = await response.json();
	}
	catch (e) {
		console.error(e);
		data = {};
	}
	if (Object.keys(data).length > 0) {
		settings = {...data};
	}

	return settings;
}

let translations = [];
async function getTranslations() {
	const culture = document.body.dataset.culture;
	const design = document.body.dataset.design;
	const translationsEndPoint = `${location.hostname === 'localhost' ? 'http://localhost:53150' : 'https://mobler.dk' }/dwapi/translations/${design}/${culture}`
	const response = await fetch(translationsEndPoint);
	let data;
	try {
		data = await response.json();
	}
	catch (e) {
		console.error(e);
		data = [];
	}
	if (data.length > 0) {
		translations = [...data];
	}
}


// Navigation should be globally available
let navigation = {}
async function getNavigation() {
	const response = await fetch('/mobler-api/v2/ecom/settings/navigation')
	let data
	try {
		data = await response.json()

	}
	catch (e) {
		console.error(e)
		data = {}
	}
	if (Object.keys(data).length > 0) {
		navigation = {...data}
	}

	return navigation
}

getTranslations()
	.then(() => getSettings())
	.then(() => getNavigation())
	.then(() => {
		Object.entries(modules).forEach(([path, module]) => {
			// @ts-ignore
			const name = path.split('/').pop().split('.').shift().toLowerCase()

			// For each Vue component found, register it
			if (document.querySelector( name) === null) return

			let els = document.querySelectorAll( name);
			els.forEach((v,i,p) => {
				const props = datasetToObject(v);
				createApp(module.default,props)
					.provide('translations', translations)
					.provide('settings', settings)
					.provide('navigation', navigation)
					.directive('click-outside', ClickOutside)
					.mount(v);
			})
		})
})

// Hide Weply when modals, menus or other overlays are open
function hideWeply() {
	const observeWeplyIframe = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].nodeName === "IFRAME" && mutation.addedNodes[0].src.includes("weply")) {
				console.log('weply is added');
				const weply = mutation.addedNodes[0];

				// When weply is added, listen for changes to the document class
				const observeMenuOpen = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.attributeName === "class") {
							const menuOpen = mutation.target.classList.contains("menu-open") || mutation.target.classList.contains("modal-open");
							if (menuOpen) {
								weply.style.setProperty("display", "none", "important");
							} else {
								weply.style.setProperty("display", "unset", "important");
							}
						}
					});
				});
				observeMenuOpen.observe(document.documentElement, { attributes: true });

				// When weply is added, listen for changes to the body
				const observeModalOpen = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.attributeName === "class") {
							const modalOpen = mutation.target.classList.contains("menu-open") || mutation.target.classList.contains("modal-open")
							if (modalOpen) {
								weply.style.setProperty("display", "none", "important");
							} else {
								weply.style.setProperty("display", "unset", "important");
							}
						}
					});
				});
				observeModalOpen.observe(document.body , { attributes: true });
			}
		});
	});

	observeWeplyIframe.observe(document.body, { childList: true, subtree: true });
}

hideWeply()


// Parse the incoming data attributes, to correctly handle JSON as well as numbers and strings
function datasetToObject(element: Element) {
    const dataset = element.dataset;
    const obj = {};

    for (const key in dataset) {
        if (dataset.hasOwnProperty(key)) {
            const value = dataset[key];

            try {
                // Try parsing as JSON
                obj[key] = JSON.parse(value);
            } catch (error) {
                // Fallback to string or number
                obj[key] = isNaN(value) ? value : Number(value);
            }
        }
    }

    return obj;
}


// ts modules
import "./ts/Models";
import "./ts/CustomBindings";
import "./ts/fontawesome-icons";



